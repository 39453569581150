import alertTemplate from '@/plugins/alert/AlertTemplate.vue'
const COLORS = {
  primary: '#334A58',
  secondary: '#adb5bd',
  ativos: '#53D800',
  inativos: '#F03E3E',
  accent: '#82B1FF',
  error: '#FF5252',
  info: '#2196F3',
  success: '#4CAF50',
  warning: '#FFC107'
}
/* eslint-disable prefer-promise-reject-errors */
export default {
  install(Vue) {
    let AlertConstructor = Vue.extend(alertTemplate)
    let alertInstance = null
    Vue.prototype.$alert = config => {
      return new Promise((resolve, reject) => {
        if (alertInstance) {
          alertInstance.closeAlert()
        }
        alertInstance = new AlertConstructor({
          el: document.createElement('div'),
          data() {
            return {
              alert_title: config.alert_title || '',
              alert_message: config.alert_message || '',
              alert_color: COLORS[config.alert_color] || COLORS['primary'],
              alert_icon: config.alert_icon || '',
              action: config.action || null,
              action_label: config.action_label
            }
          },
          methods: {
            closeAlert() {
              if (alertInstance) alertInstance.open = false
              alertInstance = null
              resolve()
            }
          }
        })
        alertInstance.open = true
        document.body.appendChild(alertInstance.$el)
      })
    }
  }
}
