const breadcrumbsHome = { name: 'Home', pathName: '/' }
const breadcrumbsErps = { name: 'ERPs', pathName: '/erps' }

export default [
  {
    path: '/erps',
    component: () => import('@/modules/erps/views/pages/Erps.vue'),
    meta: {
      title: 'ERPs',
      breadcrumbs: [breadcrumbsHome, breadcrumbsErps]
    }
  },
  {
    path: '/erps/create',
    name: 'create-erp',
    component: () => import('@/modules/erps/views/pages/Create.vue'),
    meta: {
      title: 'Criar ERP',
      breadcrumbs: [breadcrumbsHome, breadcrumbsErps]
    }
  },
  {
    path: '/erps/:id',
    component: () => import('@/modules/erps/views/pages/Create.vue'),
    meta: {
      title: 'Editar ERP',
      breadcrumbs: [breadcrumbsHome, breadcrumbsErps],
      requiresAdmin: true
    }
  }
]
