import gql from 'graphql-tag'

export const MUTATION_LOGIN = gql`
  mutation {
    localLogin {
      session_id
    }
  }
`
export const MUTATION_GOOGLE_AUTH = gql`
  mutation($token: String!) {
    googleSignIn(token: $token) {
      session_id
    }
  }
`

export const QUERY_REFRESH = gql`
  query refreshToken {
    refreshToken
  }
`
// TODO: Adicionar o has_mercapromo na query quando sincronizar o back com o da industria
export const QUERY_SINGLE_LOGIN = gql`
  query loginWithAuthCode($authCode: String!) {
    loginWithAuthCode(authCode: $authCode) {
      user {
        _id
        name
        login
        pages
        multi_user
        mf
        admin
        profiles
        email_confirmed
        ecommerce
        club
        has_pii_access
        ecommercePermissions
        account {
          _id
          name
          franchise_id
          id_external
          modules
          is_franchise
          is_franchisee
          only_mercapromo
          channels {
            ecommerce_v2 {
              active
              integration {
                url
              }
            }
          }
        }
      }
      session_id
    }
  }
`
