const breadcrumbsHome = { name: 'Home', pathName: '/' }
const breadcrumbsIntegraClient = { name: 'Integra Cliente', pathName: '/integra-client' }

export default [
  {
    path: '/integra-client',
    component: () => import('@/modules/integra_client/views/pages/integraClient.vue'),
    meta: {
      title: 'Integra Cliente',
      breadcrumbs: [breadcrumbsHome, breadcrumbsIntegraClient]
    }
  }
]
