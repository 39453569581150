import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import { helpers } from '@mf-node/mf-vue-components'
import i18n from '@/plugins/i18n'

const getWhitelabelConfiguration = helpers.whitelabel.getWhitelabelConfiguration

const opts = {
  theme: {
    dark: false,
    themes: {
      light: {
        ...getWhitelabelConfiguration('mercafacil').themes.light,
        primary: '#334A58'
      }
    }
  },
  lang: {
    t: (key, ...params) => i18n.t(key, params)
  }
}

Vue.use(Vuetify)
export default new Vuetify(opts)
