const breadcrumbsHome = { name: 'Home', pathName: '/' }
const breadcrumbsRetailers = { name: 'Varejistas', pathName: '/retailers' }

export default [
  {
    path: '/retailers',
    component: () => import('@/modules/retailers/views/pages/Retailers.vue'),
    meta: {
      title: 'Varejistas',
      breadcrumbs: [breadcrumbsHome, breadcrumbsRetailers]
    }
  },
  {
    path: '/retailers/create',
    name: 'create-retailer',
    component: () => import('@/modules/retailers/views/pages/Create.vue'),
    props: { isCreate: true },
    meta: {
      title: 'Criar varejista',
      breadcrumbs: [breadcrumbsHome, breadcrumbsRetailers]
    }
  },
  {
    path: '/retailers/:id/edit',
    component: () => import('@/modules/retailers/views/pages/Edit.vue'),
    meta: {
      title: 'Edição de varejistas',
      breadcrumbs: [breadcrumbsHome, breadcrumbsRetailers],
      requiresAdmin: true
    },
    children: [
      {
        path: 'dados-do-varejista',
        component: () => import('@/modules/retailers/views/pages/Create.vue'),
        props: { isCreate: false },
        params: true,
        meta: {
          title: 'Dados do varejista',
          breadcrumbs: [breadcrumbsHome, breadcrumbsRetailers]
        }
      },
      {
        path: 'plataformas',
        component: () => import('@/modules/retailers/views/components/tables/AccountsIndexTable.vue'),
        params: true,
        meta: {
          title: 'Plataformas do varejista',
          breadcrumbs: [breadcrumbsHome, breadcrumbsRetailers]
        }
      },
      {
        path: 'produtos-ativos',
        component: () => import('@/modules/retailers/views/components/tables/ActiveProductsIndexTable.vue'),
        params: true,
        meta: {
          title: 'Produtos Ativos',
          breadcrumbs: [breadcrumbsHome, breadcrumbsRetailers]
        }
      },
      {
        path: 'lojas',
        component: () => import('@/modules/retailers/views/components/tables/UnitsIndexTable.vue'),
        params: true,
        meta: {
          title: 'Lojas',
          breadcrumbs: [breadcrumbsHome, breadcrumbsRetailers]
        }
      },
      {
        path: 'acoes',
        component: () => import('@/modules/retailers/views/components/tables/SalesIndexTable.vue'),
        params: true,
        meta: {
          title: 'Ações',
          breadcrumbs: [breadcrumbsHome, breadcrumbsRetailers]
        }
      },
      {
        path: 'industria',
        component: () => import('@/modules/retailers/views/components/tables/IndustryOptinIndex.vue'),
        params: true,
        meta: {
          title: 'Indústria',
          breadcrumbs: [breadcrumbsHome, breadcrumbsRetailers]
        }
      },
      {
        path: 'nova-venda',
        name: 'create-sale',
        component: () => import('@/modules/retailers/views/components/forms/SalesForm.vue'),
        params: true,
        meta: {
          title: 'Nova venda',
          breadcrumbs: [breadcrumbsHome, breadcrumbsRetailers]
        }
      }
    ]
  }
]
