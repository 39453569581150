import Vue from 'vue'
import Router from 'vue-router'

// SPA Routes
import homeRoutes from '@/modules/home/routes'
import errorsRoutes from '@/modules/errors/routes'
import accountsRoutes from '@/modules/accounts/routes'
import retailersRoutes from '@/modules/retailers/routes'
import integraClientRoutes from '@/modules/integra_client/routes'
import franchisesRoutes from '@/modules/franchises/routes'
import usersRoutes from '@/modules/users/routes'
import airflowRoutes from '@/modules/airflow/routes'
import erpsRoutes from '@/modules/erps/routes'
import appRoutes from '@/modules/app/routes'
import sessionsRoutes from '@/modules/session/routes'
import featBranchRoutes from '@/modules/feature_branch/routes'
import deploymentRoutes from '@/modules/deployment/routes'
import externalBIRoutes from '@/modules/external_bi/routes'
import industryRoutes from '@/modules/industry/routes'

Vue.use(Router)

export default new Router({
  routes: [
    ...accountsRoutes,
    ...retailersRoutes,
    ...integraClientRoutes,
    ...franchisesRoutes,
    ...homeRoutes,
    ...errorsRoutes,
    ...usersRoutes,
    ...airflowRoutes,
    ...erpsRoutes,
    ...appRoutes,
    ...sessionsRoutes,
    ...featBranchRoutes,
    ...deploymentRoutes,
    ...externalBIRoutes,
    ...industryRoutes
  ]
})
