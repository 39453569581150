import moment from 'moment'

const DEFAULT_CRON = '00:01'

export const cronToTime = (cron, tzd = -3) => {
  if (!cron) {
    return DEFAULT_CRON
  }
  const array_interval = cron.split(' ')
  const formatted_time = `${array_interval[1]}:${array_interval[0].padStart(2, '0')}`
  return moment(formatted_time, 'HH:ss')
    .add(tzd, 'hours')
    .format('HH:ss')
}
