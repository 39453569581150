import snackbarTemplate from '@/plugins/snackbar/SnackbarTemplate.vue'

const SNACKBAR_COLORS = {
  error: '#FF5252',
  primary: '#002040',
  success: '#2E7D32'
}
/* eslint-disable prefer-promise-reject-errors */
export default {
  install(Vue) {
    let SnackbarConstructor = Vue.extend(snackbarTemplate)
    let snackbarInstance = null
    Vue.prototype.$snackbar = config => {
      return new Promise((resolve, reject) => {
        if (snackbarInstance) return
        snackbarInstance = new SnackbarConstructor({
          el: document.createElement('div'),
          data() {
            return {
              message: config.message || 'Ocorreu um erro, tente novamente',
              btnText: config.btnText,
              snackbarColor: config.snackbarColor ? SNACKBAR_COLORS[config.snackbarColor] || config.snackbarColor : 'grey darken-1',
              timeout: config.timeout
            }
          },
          methods: {
            closeSnackbar() {
              if (snackbarInstance) snackbarInstance.open = false
              snackbarInstance = null
              resolve()
            },
            clickButton() {
              if (snackbarInstance) snackbarInstance.open = false
              snackbarInstance = null
              reject && reject('')
            }
          }
        })
        snackbarInstance.open = true
        document.body.appendChild(snackbarInstance.$el)
      })
    }
  }
}
