export default [
  {
    path: '/expired-session',
    name: 'expired-session',
    component: () => import('@/modules/errors/ExpiredSession.vue'),
    meta: {
      title: 'Sessão expirada',
      hideSideMenu: true
    }
  },
  {
    path: '/not-authorized',
    name: 'not-authorized',
    component: () => import('@/modules/errors/RoleNotAuthorized.vue'),
    meta: {
      title: 'Não autorizad_',
      hideSideMenu: true
    }
  }
]
