const breadcrumbsHome = { name: 'Home', pathName: '/' }

export default [
  {
    path: '/implantacao',
    component: () => import('@/modules/deployment/DeploymentDashboard.vue'),
    meta: {
      title: 'Implantação',
      breadcrumbs: [breadcrumbsHome]
    }
  }
]
