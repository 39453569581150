import gql from 'graphql-tag'

export const MUTATION_INSERT_MERCATRADE = gql`
  mutation addMercatrade($input: AddMercatradeInput!) {
    addMercatrade(data: $input) {
      message
      success
    }
  }
`

export const QUERY_GET_MERCATRADE_OFFERS_PAGINATED = gql`
  query getMercatradeOffers($input: GetIndMercatradeOffersInput!, $pagination: PaginationParams!) {
    getMercatradeOffers(input: $input, pagination: $pagination) {
      data {
        _id
        db_name
        campaigns {
          sms {
            send_date
            send_time
          }
        }
        errors {
          date
          type
        }
        industry {
          _id
          name
          proposal_deadline
        }
        offers_schedule_id
        processed
        retailers {
          discounts {
            start_date
            end_date
          }
          name
          segmentation
          segmentation_days
          segmentation_offer
          segmentation_type
        }
        title
        deleted
        to_be_deleted
        deleted_at
        deleted_by_email
      }
      count
    }
  }
`

export const MUTATION_INSERT_MERCAPROMO = gql`
  mutation AddMercapromo($input: AddMercapromoInput!) {
    addMercapromo(data: $input) {
      message
      success
    }
  }
`

export const QUERY_GET_MERCAPROMO_OFFERS_PAGINATED = gql`
  query getMercapromoOffers($input: GetIndMercapromoOffersInput!, $pagination: PaginationParams!) {
    getMercapromoOffers(input: $input, pagination: $pagination) {
      data {
        _id
        db_name
        start_date
        end_date
        processed
        name
        offer_type
        proposal_deadline
        industry {
          _id
          name
        }
        errors {
          date
          type
        }
        deleted
        to_be_deleted
        deleted_at
        deleted_by_email
      }
      count
    }
  }
`

export const MUTATION_TRADE_ERRORS_REPORT = gql`
  mutation createTradeErrorsReport($emails: [String!]!, $tradeType: [TradeErrorsTradeTypes!]!, $fileFormat: FileFormatType) {
    createTradeErrorsReport(emails: $emails, tradeType: $tradeType, fileFormat: $fileFormat) {
      id
    }
  }
`

export const MUTATION_CREATE_MERCATRADE_CALENDAR = gql`
  mutation createMercatradeCalendarReport(
    $baseCalendar: [JSON!]
    $baseClient: [JSON!]
    $emails: [String!]!
    $offerType: OfferTypeEnum
    $fileFormat: FileFormatType
  ) {
    createMercatradeCalendarReport(baseCalendar: $baseCalendar, baseClient: $baseClient, emails: $emails, offerType: $offerType, fileFormat: $fileFormat) {
      id
    }
  }
`

export const QUERY_LIST_INDUSTRIES = gql`
  query listIndustries($pagination: PaginationParams!) {
    listIndustries(pagination: $pagination) {
      count
      data {
        _id
        accountId
        origin
        source
        name
      }
    }
  }
`

export const QUERY_SEGMENT_BY_INDUSTRY_ID = gql`
  query listSegmentsByIndustry($input: ListSegmentsByIndustryInput!, $pagination: PaginationParams!) {
    listSegmentsByIndustry(input: $input, pagination: $pagination) {
      count
      data {
        _id
        name
        description
      }
    }
  }
`

export const QUERY_LIST_INDUSTRY_SEGMENT_CRITERIA = gql`
  query listIndustrySegmentCriteria($input: ListIndustrySegmentCriteriaInput!, $pagination: PaginationParams!) {
    listIndustrySegmentCriteria(input: $input, pagination: $pagination) {
      count
      data {
        _id
        name
        description
        industry {
          _id
          name
        }
        created_at
        updated_at
        created_by_email
        updated_by_email
      }
    }
  }
`

export const QUERY_INDUSTRY_SEGMENT_CRITERIA_BY_ID = gql`
  query industrySegmentCriteria($input: SearchIndSegmentCriteriaInput!) {
    industrySegmentCriteria(input: $input) {
      _id
      name
      description
      industry {
        _id
        name
      }
      reference_segment {
        _id
        name
        description
      }
      created_at
      updated_at
      created_by_email
      updated_by_email
    }
  }
`

export const MUTATION_UPSERT_INDUSTRY_SEGMENT_CRITERIA = gql`
  mutation upsertIndustrySegmentCriteria($input: UpsertIndSegmentCriteriaInput!) {
    upsertIndustrySegmentCriteria(input: $input) {
      success
      message
    }
  }
`

export const MUTATION_DELETE_IND_SEGMENT_CRITERIA = gql`
  mutation deleteIndustrySegmentCriteria($input: DeleteIndSegmentCriteriaInput!) {
    deleteIndustrySegmentCriteria(input: $input) {
      success
      message
    }
  }
`

export const QUERY_LIST_INDUSTRY_SEGMENT_PRODUCTS = gql`
  query listIndustrySegmentProducts($input: ListIndustrySegmentProductsInput!, $pagination: PaginationParams!) {
    listIndustrySegmentProducts(input: $input, pagination: $pagination) {
      count
      data {
        _id
        name
        description
        industry {
          _id
          name
        }
        created_at
        updated_at
        created_by_email
        updated_by_email
      }
    }
  }
`

export const QUERY_INDUSTRY_SEGMENT_PRODUCTS_BY_ID = gql`
  query industrySegmentProducts($input: SearchIndSegmentProductsInput!) {
    industrySegmentProducts(input: $input) {
      _id
      name
      description
      eans
      industry {
        _id
        name
      }
      created_at
      updated_at
      created_by_email
      updated_by_email
    }
  }
`

export const MUTATION_UPSERT_INDUSTRY_SEGMENT_PRODUCTS = gql`
  mutation upsertIndustrySegmentProducts($input: UpsertIndSegmentProductsInput!) {
    upsertIndustrySegmentProducts(input: $input) {
      success
      message
    }
  }
`

export const MUTATION_DELETE_IND_SEGMENT_PRODUCTS = gql`
  mutation deleteIndustrySegmentProducts($input: DeleteIndSegmentProductsInput!) {
    deleteIndustrySegmentProducts(input: $input) {
      success
      message
    }
  }
`

export const MUTATION_DELETE_INDUSTRY_OFFERS = gql`
  mutation deleteIndustryOffers($input: DeleteIndustryOffersInput!) {
    deleteIndustryOffers(input: $input) {
      success
      message
    }
  }
`
