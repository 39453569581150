export const RETAILER_STATUS = [
  {
    color: '#FF5252',
    label: 'Inativo',
    value: 'INACTIVE'
  },
  {
    color: '#07DD18',
    label: 'Ativo',
    value: 'ACTIVE'
  },
  {
    color: '#EDB900',
    label: 'Churn agendado',
    value: 'CHURN_SCHEDULED'
  }
]

export const getStatusLabel = status => {
  return RETAILER_STATUS.find(({ value }) => value === status) || RETAILER_STATUS[0]
}
