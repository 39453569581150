export const formatClientNameForKeyword = function(val) {
  return val
    .replace(/[ÀÁÂÃÄÅ]/g, 'A')
    .replace(/[aáàãâä]/g, 'a')
    .replace(/[EÉÈÊË]/g, 'E')
    .replace(/[eéèêë]/g, 'e')
    .replace(/[IÍÌÎÏ]/g, 'I')
    .replace(/[iíìîï]/g, 'i')
    .replace(/[OÓÒÕÔÖ]/g, 'O')
    .replace(/[oóòõôö]/g, 'o')
    .replace(/[UÚÙÛÜ]/g, 'U')
    .replace(/[uúùûü]/g, 'u')
}
