<template>
  <v-snackbar v-model="model" top right class="mt-12 pt-12" :timeout="15000" :color="color" elevation="3">
    <v-row no-gutters class="py-3 pl-3">
      <v-col cols="auto" class="mr-3">
        <v-row justify="center" class="align-center">
          <v-col cols="auto">
            <v-icon size="50" color="white">{{ icon }}</v-icon>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="custom_margin">
        <v-row no-gutters>
          <v-col cols="12">
            <h3>{{ title }}</h3>
          </v-col>
          <v-col cols="auto" class="mt-2">
            <span v-html="message"></span>
          </v-col>
          <v-col v-if="action" class="mt-2" cols="12">
            <span class="link action-alert white--text" @click="action">{{ actionLabel }}</span>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="auto">
        <v-icon class="color_icon" @click="closeAlert">mdi-close-thick</v-icon>
      </v-col>
    </v-row>
  </v-snackbar>
</template>
<script>
/**
 * Alerta base do projeto
 * @component
 */
export default {
  props: {
    /**
     * @model
     */
    value: {
      type: Boolean,
      default: false
    },
    /**
     * Título do botão
     */
    title: {
      type: String,
      default: ''
    },
    /**
     * Message do botão
     */
    message: {
      type: String,
      default: ''
    },
    /**
     * Cor de background do header da modal
     */
    color: {
      type: String,
      default: 'success'
    },
    /**
     * Icone da alert
     * @values [mdi-bell-circle, mdi-check-circle, mdi-close-circle, mdi-alert-circle, mdi-information]
     */
    icon: {
      type: String,
      default: 'mdi-check-circle'
    },
    /**
     * Callback ao clicar no link de ação
     */
    action: {
      type: Function,
      required: false
    },
    /**
     * Texto do link de ação
     */
    actionLabel: {
      type: String,
      default: 'Ver detalhes',
      required: false
    }
  },
  data() {
    return {
      model: this.value
    }
  },
  watch: {
    model(val) {
      /**
       * Evento que realiza a atualização da model
       * @event input
       * @property {String}
       */
      this.$emit('input', val)
    },
    value() {
      this.model = this.value
    }
  },
  methods: {
    closeAlert() {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.theme--light.v-icon {
  color: #fff;
}
.custom_margin {
  margin-top: 16px;
  margin-left: 15px;
}
.action-alert {
  color: white !important;
}
</style>
