<template>
  <mf-dialog v-model="open" :title="title">
    <v-card-text>
      <div class="error-container">
        <div v-for="(error, index) in errors" :key="error.message" class="error-item">
          <span>Erro #{{ index + 1 }}</span>
          <code class="code-block">{{ error.message }}</code>
        </div>
      </div>
    </v-card-text>
    <v-card-actions>
      <mf-action-buttons
        :primary-button="{
          text: 'Copiar',
          action: copyToClipboard,
          isVisible: true
        }"
      />
    </v-card-actions>
  </mf-dialog>
</template>

<script>
export default {
  data() {
    return {
      open: false
    }
  },
  methods: {
    copyToClipboard() {
      const allErrors = this.errors.map(error => error.message).join('\n')
      navigator.clipboard.writeText(allErrors)
      this.$snackbar({ message: 'Copiado para a área de transferência', snackbarColor: '#2196F3' })
    }
  }
}
</script>

<style>
.error-container {
  max-height: 60vh;
  overflow-y: auto;
}

.error-item {
  margin-bottom: 8px;
}

.code-block {
  padding: 4px 8px !important;
  font-size: 12px !important;
}
</style>
