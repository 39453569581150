import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { createApolloClient } from 'vue-cli-plugin-apollo/graphql-client'
import { getHttpLink } from './apollo-http-link'

Vue.use(VueApollo)

// localStorage item name
export const AUTH_SESSION_ID = 'session_id'
export const isDev = process.env.NODE_ENV === 'development'

// endpoint
// export const httpEndpoint = process.env.VUE_APP_GRAPHQL_HTTP || 'https://account-svc-beta.mercafacil.com/graphql'
export const httpEndpoint = process.env.VUE_APP_GRAPHQL_HTTP || 'https://account-svc-beta.mercafacil.com/graphql'

// files
export const filesRoot = process.env.VUE_APP_FILES_ROOT || httpEndpoint.substring(0, httpEndpoint.indexOf('/graphql'))
Vue.prototype.$filesRoot = filesRoot

const defaultOptions = {
  httpEndpoint,
  persisting: false,
  websocketsOnly: false,
  ssr: false,
  httpLinkOptions: {
    uri: httpEndpoint
  },
  defaultHttpLink: false,
  link: getHttpLink()
}

export function createProvider(options = {}) {
  const { apolloClient } = createApolloClient({
    ...defaultOptions,
    ...options
  })

  const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        fetchPolicy: 'network-only'
      }
    }
  })

  return apolloProvider
}

// manually call this when user log out
export async function onLogout() {
  if (typeof localStorage !== 'undefined') {
    localStorage.removeItem(AUTH_SESSION_ID)
  }
}
