const breadcrumbsHome = { name: 'Home', pathName: '/' }

export default [
  {
    path: '/app',
    component: () => import('@/modules/app/views/pages/AppPublishing.vue'),
    meta: {
      title: 'Publicação de Apps',
      breadcrumbs: [breadcrumbsHome]
    }
  },
  {
    path: '/app-logs',
    component: () => import('@/modules/app/views/pages/AppBuildLogs.vue'),
    meta: {
      title: 'Logs e deploy',
      breadcrumbs: [breadcrumbsHome]
    }
  }
]
