export default {
  $vuetify: {
    dataIterator: {
      rowsPerPageText: 'Items per page:',
      rowsPerPageAll: 'All',
      pageText: '{0}-{1} of {2}',
      noResultsText: 'No matching records found',
      nextPage: 'Next page',
      prevPage: 'Previous page'
    },
    dataTable: {
      rowsPerPageText: 'Rows per page:'
    },
    noDataText: 'No data available'
  }
}
