<template>
  <base-alert
    v-model="open"
    :value="open"
    :message="alert_message"
    :color="alert_color"
    :icon="alert_icon"
    :title="alert_title"
    :action="action"
    :actionLabel="action_label"
    @close="closeAlert"
  ></base-alert>
</template>
<script>
import BaseAlert from '../../components/atomic-components/atoms/BaseAlert.vue'
export default {
  components: {
    BaseAlert
  },
  data() {
    return {
      open: false
    }
  }
}
</script>
