import { isNumber } from 'lodash'
import { localeString } from '@/helpers'

export default (value, digits = 0) => {
  if (value === '-') return value
  if (value || value === 0) {
    if (isNumber(value)) return localeString(value, digits)
    return localeString(value)
  } else return '-'
}
