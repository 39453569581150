<template>
  <mf-confirm-dialog
    v-model="open"
    :title="title"
    :confirm-text="confirmText"
    :cancel-text="cancelText"
    :confirm-color="confirmColor"
    :cancel-color="cancelColor"
    :message="message"
    @confirm="onConfirm"
    @cancel="onCancel"
  />
</template>

<script>
export default {
  data() {
    return {
      open: false
    }
  }
}
</script>
