import { setContext } from 'apollo-link-context'
import { createUploadLink } from 'apollo-upload-client'
import { getFingerprint } from '../services/fingerprint'
import { AUTH_SESSION_ID, httpEndpoint } from './apollo'
import { from } from 'apollo-link'
import { onError } from 'apollo-link-error'
import { removeTokens } from '../helpers/removeTokens'

function getAuth(tokenName) {
  let token
  if (typeof window !== 'undefined') token = window.localStorage.getItem(tokenName)
  return token ? 'Bearer '.concat(token) : ''
}

export const getHttpLink = () => {
  const httpLink = createUploadLink({ uri: httpEndpoint })
  let fingerprint

  // auth middleware
  const authLink = setContext((req, { headers = {} } = {}) => {
    return {
      headers: {
        authorization: getAuth(AUTH_SESSION_ID),
        ...headers
      }
    }
  })

  // fingerprint middleware
  const fingerprintLink = setContext(async (req, { headers = {} } = {}) => {
    if (!fingerprint) {
      const { visitorId } = await getFingerprint()
      fingerprint = visitorId
    }

    return {
      headers: {
        fingerprint,
        ...headers
      }
    }
  })

  // log message middleware
  const logMessageLink = setContext((operation, { headers = {} } = {}) => {
    const operationDef = operation.query.definitions[0].operation
    const logMessage = localStorage.getItem('log_message')

    if (operationDef === 'mutation' && logMessage) {
      window.localStorage.removeItem('log_message')

      return {
        headers: {
          'log-message': logMessage.replaceAll('\n', ' '),
          ...headers
        }
      }
    }
  })

  // error handler
  const errorHandlerLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path, extensions }) => {
        // console.log(`[GraphQL Error] Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}, Code: ${extensions.exception.code}`)
        if (message === 'missing.session' || message === 'authentication.missing.session.id' || message === 'invalid.session') {
          removeTokens()
          window.location.replace('/#/expired-session')
        }

        if (message === 'authentication.role.not.allowed') {
          window.location.replace('/#/not-authorized')
        }
      })
    if (networkError && networkError.statusCode === 401) window.location.replace('/#/expired-session')
    if (networkError) console.log(`[Network Error] ${networkError.message}`)
  })

  // return fingerprintLink.concat(authLink.concat(httpLink), logMessageLink)
  return from([errorHandlerLink, logMessageLink, fingerprintLink.concat(authLink.concat(httpLink))])
}
