const breadcrumbsHome = { name: 'Home', pathName: '/' }
const breadcrumbsMercapromo = { name: 'Mercapromo', pathName: '/mercapromo' }
const breadcrumbsMercatrade = { name: 'Mercatrade', pathName: '/mercatrade' }
const breadcrumbsCalendarioMercatrade = { name: 'Calendário Mercatrade', pathName: '/calendario-mercatrade' }
const breadcrumbsSegmentCriteria = { name: 'Critérios de Segmento', pathName: '/criterios-segmento' }
const breadcrumbsSegmentProducts = { name: 'Produtos de Segmento', pathName: '/produtos-segmento' }

export default [
  {
    path: '/mercapromo',
    pathName: 'mercapromo',
    component: () => import('@/modules/industry/mercapromo/views/pages/Mercapromo.vue'),
    meta: {
      title: 'Mercapromo',
      breadcrumbs: [breadcrumbsHome, breadcrumbsMercapromo]
    }
  },
  {
    path: '/mercapromo/create',
    pathName: 'create-mercapromo',
    component: () => import('@/modules/industry/mercapromo/views/pages/Create.vue'),
    meta: {
      title: 'Criar Mercapromo',
      breadcrumbs: [breadcrumbsHome, breadcrumbsMercapromo, { name: 'Criar Mercapromo', pathName: '' }],
      requiresAdmin: true
    }
  },
  {
    path: '/mercatrade',
    component: () => import('@/modules/industry/mercatrade/views/pages/Mercatrade.vue'),
    meta: {
      title: 'Mercatrade',
      breadcrumbs: [breadcrumbsHome, breadcrumbsMercatrade]
    }
  },
  {
    path: '/mercatrade/create',
    component: () => import('@/modules/industry/mercatrade/views/pages/Create.vue'),
    meta: {
      title: 'Criar Mercatrade',
      breadcrumbs: [breadcrumbsHome, breadcrumbsMercatrade, { name: 'Criar Mercatrade', pathName: '' }],
      requiresAdmin: true
    }
  },
  {
    path: '/calendario-mercatrade',
    component: () => import('@/modules/industry/mercatrade/views/pages/CalendarioMercatrade.vue'),
    meta: {
      title: 'Mercatrade',
      breadcrumbs: [breadcrumbsHome, breadcrumbsCalendarioMercatrade]
    }
  },
  {
    path: '/criterios-segmento',
    name: 'criterios-segmento',
    component: () => import('@/modules/industry/segments/views/pages/SegmentCriteria.vue'),
    meta: {
      title: 'Critérios de Segmento',
      breadcrumbs: [breadcrumbsHome, breadcrumbsSegmentCriteria]
    }
  },
  {
    path: '/criterios-segmento/criar',
    name: 'criar-criterio-segmento',
    component: () => import('@/modules/industry/segments/views/pages/CreateCriteria.vue'),
    meta: {
      title: 'Critérios de Segmento',
      breadcrumbs: [breadcrumbsHome, breadcrumbsSegmentCriteria, { name: 'Criar critério', pathName: '' }]
    }
  },
  {
    path: '/criterios-segmento/editar/:id',
    name: 'editar-criterio-segmento',
    props: true,
    component: () => import('@/modules/industry/segments/views/pages/CreateCriteria.vue'),
    meta: {
      title: 'Critérios de Segmento',
      breadcrumbs: [breadcrumbsHome, breadcrumbsSegmentCriteria, { name: 'Editar critério', pathName: '' }]
    }
  },
  {
    path: '/produtos-segmento',
    name: 'produtos-segmento',
    component: () => import('@/modules/industry/segments/views/pages/SegmentProducts.vue'),
    meta: {
      title: 'Produtos de Segmento',
      breadcrumbs: [breadcrumbsHome, breadcrumbsSegmentProducts]
    }
  },
  {
    path: '/produtos-segmento/criar',
    name: 'criar-produtos-segmento',
    component: () => import('@/modules/industry/segments/views/pages/CreateProducts.vue'),
    meta: {
      title: 'Produtos de Segmento',
      breadcrumbs: [breadcrumbsHome, breadcrumbsSegmentProducts, { name: 'Criar produtos', pathName: '' }]
    }
  },
  {
    path: '/produtos-segmento/editar/:id',
    name: 'editar-produtos-segmento',
    props: true,
    component: () => import('@/modules/industry/segments/views/pages/CreateProducts.vue'),
    meta: {
      title: 'Produtos de Segmento',
      breadcrumbs: [breadcrumbsHome, breadcrumbsSegmentProducts, { name: 'Editar produtos', pathName: '' }]
    }
  }
]
