export default [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    component: () => import('@/modules/home/Home.vue'),
    meta: {
      title: 'Home'
    }
  }
]
