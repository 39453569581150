import errorDialogTemplate from '@/plugins/errorDialog/ErrorDialogTemplate.vue'

/* eslint-disable prefer-promise-reject-errors */
export default {
  install(Vue, options) {
    let errorDialogInstance = null
    Vue.prototype.$errorDialog = config => {
      if (config) errorDialogTemplate.props = {}
      let ErrorConstructor = Vue.extend(Object.assign({ vuetify: options.vuetify }, errorDialogTemplate))
      return new Promise((resolve, reject) => {
        if (errorDialogInstance) {
          return
        }
        errorDialogInstance = new ErrorConstructor({
          el: document.createElement('div'),
          data() {
            return {
              title: config.title || '',
              errors: config.errors || []
            }
          },
          watch: {
            open: function(val, oldVal) {
              if (val === false) {
                errorDialogInstance = null
              }
            }
          }
        })
        errorDialogInstance.open = true
        document.body.appendChild(errorDialogInstance.$el)
      })
    }
  }
}
