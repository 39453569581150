import FingerprintJS from '@fingerprintjs/fingerprintjs'

const fpPromise = FingerprintJS.load()
export const getFingerprint = async () => {
  const fp = await fpPromise
  try {
    const result = await fp.get()
    return result
  } catch (error) {
    console.log(error.message)
  }
}
