// polished in a long conversation with chatGPT

function _rgbToHex(r, g, b) {
  r = r.toString(16).padStart(2, '0')
  g = g.toString(16).padStart(2, '0')
  b = b.toString(16).padStart(2, '0')
  return `#${r}${g}${b}`
}

function _hue2rgb(p, q, t) {
  if (t < 0) t += 1
  if (t > 1) t -= 1
  if (t < 1 / 6) return p + (q - p) * 6 * t
  if (t < 1 / 2) return q
  if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6
  return p
}

function _hslToRgb(h, s, l) {
  let r, g, b

  if (s === 0) {
    r = g = b = l
  } else {
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s
    const p = 2 * l - q
    r = _hue2rgb(p, q, h + 1 / 3)
    g = _hue2rgb(p, q, h)
    b = _hue2rgb(p, q, h - 1 / 3)
  }

  return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)]
}

function rgbToHsl(r, g, b) {
  r /= 255
  g /= 255
  b /= 255
  const max = Math.max(r, g, b)
  const min = Math.min(r, g, b)
  let h
  let s
  let l = (max + min) / 2

  if (max === min) {
    h = s = 0
  } else {
    const d = max - min
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0)
        break
      case g:
        h = (b - r) / d + 2
        break
      case b:
        h = (r - g) / d + 4
        break
    }
    h /= 6
  }

  return [h, s, l]
}

export function genHexLightDark(hexBase) {
  const r = parseInt(hexBase.substring(1, 3), 16)
  const g = parseInt(hexBase.substring(3, 5), 16)
  const b = parseInt(hexBase.substring(5, 7), 16)

  const hsl = rgbToHsl(r, g, b)

  const hslLight = [hsl[0], hsl[1], Math.min(hsl[2] * 1.5, 1)]

  const rgbLight = _hslToRgb(hslLight[0], hslLight[1], hslLight[2])
  const hexLight = _rgbToHex(rgbLight[0], rgbLight[1], rgbLight[2]).toUpperCase()

  const rDark = Math.max(Math.round(r * 0.5), 0)
  const gDark = Math.max(Math.round(g * 0.5), 0)
  const bDark = Math.max(Math.round(b * 0.5), 0)
  const hexDark = `#${rDark.toString(16).padStart(2, '0')}${gDark.toString(16).padStart(2, '0')}${bDark.toString(16).padStart(2, '0')}`.toUpperCase()

  return [hexLight, hexDark]
}
