const coreLocales = {
  $vuetify: {
    close: 'Fechar',
    badge: '',
    calendar: {
      moreEvents: 'Mais {0}'
    },
    carousel: {
      next: 'keyboard_arrow_right',
      prev: 'keyboard_arrow_left',
      ariaLabel: {
        delimiter: 'fiber_manual_record'
      }
    },
    dataIterator: {
      pageText: '{0}-{1} de {2}',
      noResultsText: 'Nenhum resultado encontrado',
      loadingText: 'Carregando...'
    },
    dataTable: {
      itemsPerPageText: 'Itens por página:',
      ariaLabel: {
        sortDescending: ': Ordenação decrescente. Ative para remover a ordenação.',
        sortAscending: ': Ordenação crescente. Ative para alternar para decrescente.',
        sortNone: ': Não ordenado. Ative para ordenar de forma crescente.'
      }
    },
    dataFooter: {
      itemsPerPageText: 'Itens por página:',
      itemsPerPageAll: 'Todos',
      pageText: '{0}-{1} de {2}',
      nextPage: 'Página seguinte',
      prevPage: 'Página anterior',
      firstPage: 'Primeira página',
      lastPage: 'Última página'
    },
    datePicker: {
      itemsSelected: '{0} selected'
    },
    noDataText: 'Não há dados'
  }
}

export default {
  ...coreLocales
}
