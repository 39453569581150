const breadcrumbsHome = { name: 'Home', pathName: '/' }
const breadcrumbsFranchises = { name: 'Redes', pathName: '/franchises' }

export default [
  {
    path: '/franchises',
    component: () => import('@/modules/franchises/views/Franchises.vue'),
    meta: {
      title: 'Redes',
      breadcrumbs: [breadcrumbsHome, breadcrumbsFranchises]
    }
  },
  {
    path: '/franchises/create',
    name: 'create-franchise',
    component: () => import('@/modules/franchises/views/Create.vue'),
    meta: {
      title: 'Criar rede',
      breadcrumbs: [breadcrumbsHome, breadcrumbsFranchises]
    }
  },
  {
    path: '/franchises/:id/edit',
    component: () => import('@/modules/franchises/views/Edit.vue'),
    meta: {
      title: 'Edição de redes',
      breadcrumbs: [breadcrumbsHome, breadcrumbsFranchises],
      requiresAdmin: true
    },
    children: [
      {
        path: 'landing-page',
        component: () => import('@/modules/franchises/views/config_landing_page/ConfigLandingPage.vue'),
        params: true,
        meta: {
          title: 'teste',
          breadcrumbs: [breadcrumbsHome, breadcrumbsFranchises]
        }
      },
      {
        path: 'dados-da-rede',
        component: () => import('@/modules/franchises/views/Create.vue'),
        params: true,
        meta: {
          title: 'Dados da rede',
          breadcrumbs: [breadcrumbsHome, breadcrumbsFranchises]
        }
      },
      {
        path: 'produtos-ativos',
        component: () => import('@/modules/franchises/components/tables/ActiveProductsIndexTable'),
        params: true,
        meta: {
          title: 'Produtos Ativos',
          breadcrumbs: [breadcrumbsHome, breadcrumbsFranchises]
        }
      },
      {
        path: 'acoes',
        component: () => import('@/modules/franchises/components/tables/SalesHistoryTable'),
        params: true,
        meta: {
          title: 'Ações',
          breadcrumbs: [breadcrumbsHome, breadcrumbsFranchises]
        }
      },
      {
        path: 'create-sale-franchise',
        name: 'create-sale-franchise',
        component: () => import('@/modules/franchises/views/CreateSale.vue'),
        params: true,
        meta: {
          title: 'Vendas',
          breadcrumbs: [breadcrumbsHome, breadcrumbsFranchises]
        }
      }
    ]
  }
]
