export default {
  install(Vue) {
    // Session
    const getSessionId = () => {
      return window.localStorage.getItem('session_id')
    }
    const setSessionId = sessionId => {
      if (!sessionId) return
      window.localStorage.setItem('session_id', sessionId)
    }
    const removeSessionId = () => {
      window.localStorage.removeItem('session_id')
    }

    // authCode
    const getAuthCode = () => {
      return window.localStorage.getItem('authCode')
    }
    const setAuthCode = authCode => {
      if (!authCode) return
      window.localStorage.setItem('authCode', authCode)
    }
    const removeAuthCode = () => {
      window.localStorage.removeItem('authCode')
    }

    // Logout
    const logout = () => {
      window.localStorage.removeItem('session_id')
      window.localStorage.removeItem('loggedUser')
      window.localStorage.removeItem('fingerprint')
      window.localStorage.removeItem('is-admin')
      window.localStorage.removeItem('token')
      window.localStorage.removeItem('admin-configuration')
      window.localStorage.removeItem('authCode')
    }

    // Logged User

    const isLogged = () => {
      return window.localStorage.getItem('loggedUser') !== null
    }

    const getLoggedUser = () => {
      const user = JSON.parse(window.localStorage.getItem('loggedStarlordUser'))
      if (!user) return { name: '', email: '' }
      return user
    }

    const setLoggedUser = loggedUser => {
      if (!loggedUser) return
      window.localStorage.setItem('loggedStarlordUser', JSON.stringify(loggedUser))
    }

    const setFingerPrint = fingerPrint => {
      window.localStorage.setItem('fingerprint', JSON.stringify(fingerPrint))
    }

    Vue.prototype.$setFingerPrint = Vue.setFingerPrint = setFingerPrint

    // Session ID
    Vue.prototype.$getSessionId = Vue.getSessionId = getSessionId
    Vue.prototype.$setSessionId = Vue.setSessionId = setSessionId
    Vue.prototype.$removeSessionId = Vue.removeSessionId = removeSessionId

    // Logout
    Vue.prototype.$logout = Vue.logout = logout

    // authCode
    Vue.prototype.$getAuthCode = Vue.getAuthCode = getAuthCode
    Vue.prototype.$setAuthCode = Vue.setAuthCode = setAuthCode
    Vue.prototype.$removeAuthCode = Vue.removeAuthCode = removeAuthCode

    // Logged User
    Vue.prototype.$isLogged = Vue.isLogged = isLogged
    Vue.prototype.$getLoggedUser = Vue.getLoggedUser = getLoggedUser
    Vue.prototype.$setLoggedUser = Vue.setLoggedUser = setLoggedUser
  }
}
