<template>
  <v-app :style="darkMode ? '' : 'background-color: #f3f3f3 !important;'">
    <v-content>
      <v-container v-if="checkedToken" class="pa-0" fluid>
        <side-menu v-if="!$route.meta.hideSideMenu" />
        <toolbar v-if="!$route.meta.hideToolbar" />
        <v-slide-x-transition mode="out-in">
          <router-view class="child-view" />
        </v-slide-x-transition>
      </v-container>
      <mf-loading-dialog v-else :loading="!checkedToken" />
    </v-content>
  </v-app>
</template>

<script>
import { getFingerprint } from '@/services/fingerprint.js'
import { formattedRedirectAfterLogin, formattedRedirectLogout } from '@/helpers/login'
import { QUERY_SINGLE_LOGIN, QUERY_REFRESH } from '@/modules/login/graphql'
import { mapActions } from 'pinia'
import { useIndustryStore } from '@/stores'

export default {
  name: 'App',
  components: {
    SideMenu: () => import('@/components/base/SideMenu.vue'),
    Toolbar: () => import('@/components/base/Toolbar.vue')
  },
  data: () => ({
    checkedToken: false
  }),
  computed: {
    darkMode: {
      get() {
        return localStorage.getItem('dark_theme') === 'true'
      }
    }
  },
  watch: {
    $route(route) {
      if (route?.query?.auth_code) {
        this.removeAuthCodeFromURL()
      }
    }
  },
  async created() {
    if (this.$getSessionId()) {
      this.verifyRefreshToken()
    } else {
      await this.validateAuthcode()
    }
    await this.fetchIndustries()
  },
  mounted() {
    const themeMode = localStorage.getItem('dark_theme')
    if (themeMode && themeMode === 'true') this.$vuetify.theme.dark = true
    if (themeMode && themeMode === 'false') this.$vuetify.theme.dark = false
  },
  methods: {
    ...mapActions(useIndustryStore, ['fetchIndustries']),

    async validateAuthcode() {
      this.checkedToken = false

      try {
        const authCode = this.getAuthCodeFromURL()
        let hash = window.localStorage.getItem('redirectAfterLogin')

        if (!authCode) {
          const singleLoginURL = this.$microservicesUrls['mfLogin']
          const host = this.getHost()
          hash = hash || window.location.hash.slice(1) || ''
          window.localStorage.setItem('redirectAfterLogin', hash)
          window.location.href = formattedRedirectAfterLogin(singleLoginURL, host, hash)
        }

        this.$setAuthCode(authCode)

        const {
          data: {
            loginWithAuthCode: { session_id, user }
          }
        } = await this.$apollo.query({
          query: QUERY_SINGLE_LOGIN,
          fetchPolicy: 'no-cache',
          variables: {
            authCode: this.$getAuthCode()
          },
          context: {
            uri: this.$microservicesUrls['hulk']
          }
        })

        await this.setUserData(user, session_id)

        if (hash) this.redirectToSpecificRoute(hash)
        else this.$router.go('/home')
      } catch (error) {
        const singleLoginURL = this.$microservicesUrls['mfLogin']
        const host = location.href.slice(0, location.href.indexOf('#'))
        const hash = window.localStorage.getItem('redirectAfterLogin') || ''

        this.$logout()
        window.location.href = formattedRedirectLogout(singleLoginURL, host, hash)
      } finally {
        this.checkedToken = true
      }
    },
    getAuthCodeFromURL() {
      return window.location.hash.slice(window.location.hash.indexOf('?') + 1).split('=')[1]
    },
    getHost() {
      return location.href.slice(0, location.href.indexOf('#'))
    },
    redirectToSpecificRoute(route) {
      window.localStorage.removeItem('redirectAfterLogin')
      this.$router.push(route)
    },
    async setUserData(user, session_id) {
      const fingerprint = await getFingerprint()
      this.$setFingerPrint(fingerprint)

      this.$setSessionId(session_id)

      this.$setLoggedUser({
        name: user.name,
        username: user.login,
        mf: user.mf,
        admin: user.admin,
        profiles: user.profiles,
        id_user: user._id
      })

      const autoRedirectRoute = window.localStorage.getItem('redirectAfterLogin')
      if (autoRedirectRoute) this.redirectToSpecificRoute(autoRedirectRoute)
      else {
        this.$router.go('/home')
      }
    },
    removeAuthCodeFromURL() {
      this.$router.replace({
        ...this.$router.currentRoute,
        query: {
          auth_code: undefined
        }
      })
    },
    verifyRefreshToken() {
      this.checkedToken = false
      this.$apollo
        .query({
          query: QUERY_REFRESH,
          fetchPolicy: 'no-cache',
          context: {
            uri: this.$microservicesUrls['hulk']
          }
        })
        .then(({ data: { refreshToken } }) => {
          this.checkedToken = true
          if (!refreshToken.length) {
            this.$router.push('/expired-session')
          }
        })
        .catch(() => {
          this.checkedToken = true
          this.$router.push('/expired-session')
        })
    }
  }
}
</script>

<style lang="scss">
body {
  background-color: #f3f3f3 !important;
}
.v-application,
.base-page-layout {
  font-family: 'Lato', sans-serif;
}
</style>
