import { DateTime } from 'luxon'

export const isPositive = value => {
  return Boolean(value > 0)
}

export const isFuture = value => {
  if (!value) return false
  const dateNow = DateTime.now().toFormat('yyyy-MM-dd')
  return dateNow <= DateTime.fromFormat(value, 'yyyy-MM-dd').toFormat('yyyy-MM-dd')
}

export const timeIsFuture = (date, value) => {
  const dateNow = DateTime.now().toFormat('yyyy-MM-dd')
  const timeNow = DateTime.now().valueOf()
  const formatedValue = DateTime.fromFormat(value, 'T').valueOf()
  if (dateNow === date && Boolean(date && value)) return timeNow < formatedValue
  else return true
}

export const timeIsFuturePlusThirtyMinutes = (date, value) => {
  const dateNow = DateTime.now().toFormat('yyyy-MM-dd')
  const timeNow = DateTime.now()
    .plus({ minutes: 30 })
    .valueOf()
  const formatedValue = DateTime.fromFormat(value, 'T').valueOf()
  if (dateNow === date && Boolean(date && value)) return timeNow < formatedValue
  else return true
}

export const notOnlySpace = value => {
  if (!value) return true
  const valueTotest = value.trim()
  return Boolean(valueTotest.length)
}

export const validCnpj = cnpj => {
  if (cnpj === null || cnpj === undefined || cnpj === '') return true

  cnpj = cnpj.replace(/[^\d]+/g, '')
  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj.length !== 14 ||
    cnpj === '00000000000000' ||
    cnpj === '11111111111111' ||
    cnpj === '22222222222222' ||
    cnpj === '33333333333333' ||
    cnpj === '44444444444444' ||
    cnpj === '55555555555555' ||
    cnpj === '66666666666666' ||
    cnpj === '77777777777777' ||
    cnpj === '88888888888888' ||
    cnpj === '99999999999999'
  ) {
    return false
  }

  // Valida DVs
  let tamanho = cnpj.length - 2
  let numeros = cnpj.substring(0, tamanho)
  let digitos = cnpj.substring(tamanho)
  let soma = 0
  let pos = tamanho - 7
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--
    if (pos < 2) {
      pos = 9
    }
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
  if (String(resultado) !== digitos.charAt(0)) {
    return false
  }

  tamanho = tamanho + 1
  numeros = cnpj.substring(0, tamanho)
  soma = 0
  pos = tamanho - 7
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--
    if (pos < 2) {
      pos = 9
    }
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
  if (String(resultado) !== digitos.charAt(1)) {
    return false
  }
  return true
}

export const isUrl = value => {
  if (!value) return true
  return /([\w-]+\.)+[\w-]+(\[\w- .\?%&=]*)?/.test(value)
}

export const isUrlPrefix = value => {
  if (!value) return true
  return /^(https):\/\/[^ "]+$/.test(value)
}

export const validBirthdate = value => {
  if (!value) {
    return true
  }
  const birthday = DateTime.fromFormat(value.toString(), 'dd/MM/yyyy')

  return DateTime.fromISO('1900-01-01') < birthday && birthday < DateTime.now()
}

export const strongPassword = value => {
  const containsUppercase = /[A-Z]/.test(value)
  const containsLowercase = /[a-z]/.test(value)
  const containsNumber = /[0-9]/.test(value)
  return containsUppercase && containsLowercase && containsNumber
}

export const validEmail = value => {
  const regExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
  return regExp.test(value)
}

export const mustContainMinLengthCharsNoWhiteSpace = (chars, minLength) => chars?.match(/\S/g)?.length >= minLength

export const parseNumber = (number, type = 'int') => {
  const typesFunc = {
    int: parseInt,
    float: parseFloat
  }

  const numberParsed = typesFunc[type](number)

  return !Number.isNaN(numberParsed) ? numberParsed : 0
}
