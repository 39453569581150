const breadcrumbsHome = { name: 'Home', pathName: '/' }

export default [
  {
    path: '/airflow',
    component: () => import('@/modules/airflow/AirflowOverview.vue'),
    meta: {
      title: 'Airflow Dags',
      breadcrumbs: [breadcrumbsHome]
    }
  }
]
