const breadcrumbsHome = { name: 'Home', pathName: '/' }
const breadcrumbsUsers = { name: 'Usuários', pathName: '/usuarios' }

export default [
  {
    path: '/usuarios',
    component: () => import('@/modules/users/views/Users.vue'),
    meta: {
      title: 'Usuários Internos',
      breadcrumbs: [breadcrumbsHome, breadcrumbsUsers]
    }
  },
  {
    path: '/usuarios/cadastro',
    component: () => import('@/modules/users/views/Create.vue'),
    meta: {
      title: 'Cadastrar Usuário',
      breadcrumbs: [breadcrumbsHome, breadcrumbsUsers]
    }
  },
  {
    path: '/usuarios/:id/detalhes',
    component: () => import('@/modules/users/views/Edit.vue'),
    meta: {
      title: 'Detalhes do Usuário',
      breadcrumbs: [breadcrumbsHome, breadcrumbsUsers],
      requiresAdmin: true
    },
    children: [
      {
        path: 'dados-gerais',
        component: () => import('@/modules/users/views/GeneralData.vue'),
        params: true,
        meta: {
          title: 'Dados Gerais',
          breadcrumbs: [breadcrumbsHome, breadcrumbsUsers]
        }
      }
    ]
  }
]
