export const deepDelete = (obj, keys) => {
  if (!Array.isArray(keys)) keys = [keys]
  const deepDeleteRec = obj => {
    if (Array.isArray(obj)) {
      // isarray
      obj.forEach(child => deepDeleteRec(child))
    } else if (obj && obj.constructor === Object) {
      // isobject
      keys.forEach(key => delete obj[key])
      Object.keys(obj).forEach(key => deepDeleteRec(obj[key]))
    }
  }
  deepDeleteRec(obj)
}
